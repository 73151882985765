import React, { useState, useEffect } from "react";
import Layout from "./Layout";

async function LogMessage(message) {
  const url = "api/Logging/LogMessage?mess=" + message;
  const response = await fetch(url);
  return await response.json();
}

async function Decrypt(message) {
  const url = "api/Decryption/DecryptRequest?encrypted=" + message;
  const response = await fetch(url);
  return await response.json();
}

const QueryParams = () => {
  const [Decrypting, setDecrypting] = useState(true);
  const [DecryptedData, setDecryptedData] = useState(null);
  const [DecryptionFailed, setDecryptionFailed] = useState(false);
  const [decryptionFailedReason, setDecryptionFailedReason] = useState("");

  useEffect(() => {
    var url = window.location.href;
    LogMessage(encodeURIComponent("Application called using url: " + url));
    var ges = url.split("?");
    var stringToDecrypt = decodeURIComponent(ges[1]);
    Decrypt(stringToDecrypt).then((data) => {
      if (data.application != null) {
        var langsplit = data.language.split("-");
        const shortlang = langsplit[0];
        setDecryptedData({
          phone: data.phone,
          unique: "PMRN^" + data.pmrn,
          language:  data.application === "HYPERSPACE" ? "en" : shortlang,
          felanguage:
            data.application === "HYPERSPACE" ? "enh" : shortlang + "g",
          application: data.application,
        });
        setDecrypting(false);
      } else {
        setDecryptionFailed(true);
        setDecryptionFailedReason("Decryption error");
      }
    });
  }, [setDecrypting, setDecryptionFailed]);
  if (DecryptionFailed) {
    return <p>{decryptionFailedReason}</p>;
  } else if (Decrypting) {
    return <p>decrypting</p>;
  } else {
    return <Layout appContext={DecryptedData}></Layout>;
  }
};

export default QueryParams;
