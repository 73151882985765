import React from "react";
import { Route, Switch } from "react-router";
import NavError from "./components/NavError";
import QueryParams from "./components/QueryParams";

import "./custom.css";
const App = () => {
  return (
    <Switch>
      <Route exact path="/qp" component={QueryParams}></Route>
      <Route component={NavError}></Route>
    </Switch>
  );
};
export default App;
