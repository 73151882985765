import React from "react";
import { useTranslation } from "react-i18next";

const NavError = () => {
  const { t } = useTranslation();
  return (
    <div align="center" className="intro">
      <div style={{ width: "95%" }}>
        <br />
        <h3 className="bigheader" align="left">
          Error
        </h3>
        <p align="left" className="headertext">
          {t("nav error")}.
        </p>
        <br />
      </div>
    </div>
  );
};
export default NavError;
