import React from "react";
import ReactDOM from "react-dom";

const MessagePopupMobile = (props) => {
  return ReactDOM.createPortal(
    <div className="w3-modal" style={{ display: "block", zIndex: "20000" }}>
      <div
        className="w3-modal-content center"
        style={{
          width: "65%",
          height: "40%",
        }}
      >
        <div style={{ height: "100%", width: "100%" }}>
          <table style={{ height: "100%", width: "100%" }}>
            <tbody>
              <tr style={{ height: "0%" }}>
                <td align="right">
                  <button
                    onClick={props.onCloseClick}
                    className="w3-display-topright"
                  >
                    &times;
                  </button>
                </td>
              </tr>
              <tr>
                <td
                  align="center"
                  style={{
                    paddingLeft: "2%",
                    paddingBottom: "2%",
                    paddingRight: "2%",
                    paddingTop: "1%",
                  }}
                >
                  {props.message !== ""
                    ? props.message
                    : "Default opt out notice"}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>,
    document.querySelector("#modal")
  );
};

export default MessagePopupMobile;
