import React, { useState, useContext } from "react";
import NoText from "../shared/images/notext.png";
import YesText from "../shared/images/yestext.png";
import AppContexts from "../contexts/AppContexts.js";
import Spinner from "../shared/images/spinner.gif";
import FailedIcon from "../shared/images/Failed.JPG";
import MessagePopupNM from "./MessagePopupNM";
import MessagePopupMobile from "./MessagePopupMobile";
import ReactTooltip from "react-tooltip";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";

async function updateProgramData(
  phone,
  unique,
  category,
  oldconsent_value,
  application
) {
  // do the toggle here
  const newconsent_value = oldconsent_value === "Y" ? "N" : "Y";
  const url =
    "api/TextingPreferences/updateconsentget?phone=" +
    phone +
    "&unique=" +
    unique +
    "&category=" +
    category +
    "&consentvalue=" +
    newconsent_value +
    "&application=" +
    application;
  const response = await fetch(url);
  return await response.json();
}

const ToggleIcon = (props) => {
  const handleClick = async () => {
    setUpdating(true);
    return updateProgramData(
      myContext.phone,
      myContext.unique,
      props.category,
      ConsentValue,
      myContext.application
    ).then((data) => {
      if (data.resultCode === "1") {
        var NCV = ConsentValue === "Y" ? "N" : "Y";
        setConsentValue(NCV);
        setUpdating(false);
        if (NCV === "N") {
          setDisplayMessage(true);
        }
      } else {
        setUpdating(false);
        setFailed(true);
        setFailedReason("Error: " + data.errorCode + " " + data.errorMessage);
        if (isMobile) setDisplayMEMessage(true);
      }
    });
  };
  const myContext = useContext(AppContexts);
  const [ConsentValue, setConsentValue] = useState(props.consent_value);
  const [Updating, setUpdating] = useState(false);
  const [Failed, setFailed] = useState(false);
  const [FailedReason, setFailedReason] = useState(false);
  const [displayMessage, setDisplayMessage] = useState(false);
  const [displayMEMessage, setDisplayMEMessage] = useState(false);

  const [ix] = useState(props.ix);
  const closeDisplayMessage = () => {
    setDisplayMessage(false);
  };
  const closeDisplayMEMessage = () => {
    setDisplayMEMessage(false);
  };
  const { t } = useTranslation();
  return (
    <div>
      {!Updating ? (
        <div onClick={handleClick}>
          <img
            alt=""
            data-for={"itip" + ix}
            data-tip={
              Failed
                ? t("update failed") + ". " + FailedReason
                : ConsentValue === "Y"
                ? t("click off")
                : t("click on")
            }
            data-iscapture="true"
            src={Failed ? FailedIcon : ConsentValue === "Y" ? YesText : NoText}
            className="clickableimage"
            height="25"
            width="25"
          ></img>
          <ReactTooltip
            id={"itip" + ix}
            place="left"
            type="info"
            effect="solid"
            multiline={true}
          />
        </div>
      ) : (
        <div>
          <img alt="" height="25" width="25" src={Spinner}></img>
        </div>
      )}

      {/* error message only for mobile */}
      {displayMEMessage && (
        <MessagePopupMobile
          onCloseClick={closeDisplayMEMessage}
          message={t("update failed") + ". " + FailedReason}
        ></MessagePopupMobile>
      )}

      {displayMessage &&
        (isMobile ? (
          <MessagePopupMobile
            onCloseClick={closeDisplayMessage}
            message={
              props.optout_notice !== ""
                ? props.optout_notice
                : t("default no optout")
            }
          ></MessagePopupMobile>
        ) : (
          <MessagePopupNM
            onCloseClick={closeDisplayMessage}
            message={
              props.optout_notice !== ""
                ? props.optout_notice
                : t("default no optout")
            }
          ></MessagePopupNM>
        ))}
    </div>
  );
};
export default ToggleIcon;
