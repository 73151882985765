import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";

// languages are en,es,ht,pt,fr
// suffixed with h or g depending on calling application

  i18n.use(Backend).use(initReactI18next).init({
    lng: "enh",
    fallbackLng: "enh",
  });

export default i18n;
