import React from "react";
import MGBHeader from "./MGBHeader";
import ProgramList from "./ProgramList";
import AppContexts from "../contexts/AppContexts.js";
import i18n from "./i18n";

function Layout(props) {
  i18n.changeLanguage(props.appContext.felanguage);
  return (
    <AppContexts.Provider value={props.appContext}>
      <div>
        <MGBHeader></MGBHeader>
        <ProgramList />
      </div>
    </AppContexts.Provider>
  );
}

export default Layout;
