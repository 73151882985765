import React, { useState, useEffect, useContext } from "react";
import ToggleIcon from "./ToggleIcon";
import AppContexts from "../contexts/AppContexts.js";
import info from "../shared/images/info.png";

import ReactTooltip from "react-tooltip";
import { useTranslation } from "react-i18next";

async function populateProgramData(phone, unique, language, application) {
  const url =
    "api/TextingPreferences/getconsentenhancedget?phone=" +
    phone +
    "&unique=" +
    unique +
    "&language=" +
    language +
    "&application=" +
    application;
  const response = await fetch(url);
  return response.json();
}

const ProgramList = () => {
  const myContext = useContext(AppContexts);
  // const application=myContext.
  const [Preferences, setPreferences] = useState(null);
  const [Loading, setLoading] = useState(true);
  const [LoadingFailed, setLoadingFailed] = useState(false);
  const [LoadingFailedReason, setLoadingFailedReason] = useState("");
  const { t } = useTranslation();
  const MainList = () => {
    return (
      <div className="table-responsive">
        <table width="80%" align="center">
          <tbody>
            {Preferences.categories ? (
              Preferences.categories.map((program, ix) => (
                <tr className="border_bottom" key={program.category}>
                  <td
                    align="left"
                    valign="top"
                    style={{
                      paddingTop: "15px",
                      paddingBottom: "12px",
                    }}
                  >
                    {program.name}&nbsp;&nbsp;
                    <img
                      alt=""
                      data-for={"ttip" + ix}
                      data-tip={
                        program.description
                          ? program.description
                          : t("default no description")
                      }
                      data-iscapture="true"
                      className="hoverimage"
                      height="16"
                      width="16"
                      src={info}
                      //title={program.description ? program.description : "N/A"}
                    ></img>
                    <ReactTooltip
                      className={"tooltip"}
                      id={"ttip" + ix}
                      place="right"
                      type="info"
                      effect="solid"
                      multiline={true}
                    />
                  </td>
                  <td
                    width="15%"
                    align="left"
                    style={{
                      paddingTop: "15px",
                      paddingBottom: "5px",
                      paddingRight: "15px",
                    }}
                  >
                    <ToggleIcon
                      consent_value={program.consent_value}
                      category={program.category}
                      optout_notice={program.optout_notice}
                      ix={ix}
                    ></ToggleIcon>
                  </td>
                </tr>
              ))
            ) : (
              <p>{t("no programs")}</p>
            )}
          </tbody>
        </table>
      </div>
    );
  };

  const LoadingDisp = () => {
    return <p>{t("data loading")}.</p>;
  };

  const FailedDisp = (reason) => {
    return (
      <div>
        <p>{t("data loading failed")}</p>
        <p> {reason}</p>
      </div>
    );
  };

  useEffect(() => {
    populateProgramData(
      myContext.phone,
      myContext.unique,
      myContext.language,
      myContext.application
    ).then((data) => {
      if (data.errorcode === "0") {
        setPreferences(data);
        console.dir(data);
        setLoading(false);
      } else {
        setLoadingFailedReason(
          "Error: " + data.errorcode + " " + data.errormsg
        );
        setLoadingFailed(true);
      }
    });
  }, [setPreferences, myContext]);

  return (
    <div>
      {LoadingFailed
        ? FailedDisp(LoadingFailedReason)
        : Loading
        ? LoadingDisp()
        : MainList()}
    </div>
  );
};
export default ProgramList;
